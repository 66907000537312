export default {
    zh: {
        tradeNoQuery: '运单查询',
        inSearchTips: '请输入要跟踪的订单单号（多个单号请使用“空格”或者“enter回车”）',
        tradeLenLimitTips: '最多支持同时查询10条运单信息',
        safetyCertificate: '安全认证',
        searchRes: '查询结果：',
        trailTip1: '注意：',
        trailTip2: '绿色',
        trailTip3: '字体的轨迹由第三方承运商提供，本平台仅作展示，不担保其准确性。',
        NoLenlimitTip: '最多输入10个运单号！',
        NoRepeatTip: '运单号重复！',
        tradeTip: '请输入有效运单号！',
        verificationTip: '请输入验证码！',
        tradeNo: '运单号',
        tradeNoErr: '未搜索到该单号，可能尚未录入或单号错误',
        usedTime:'已用时',
        shipType:'运输类型',
    },
    km: {
        tradeNoQuery: 'ឆែកវិក័យបត្រ',
        inSearchTips: 'សូមបញ្ចូលលេខកញ្ចប់ដែលត្រូវតាមដានលំដាប់ (សម្រាប់លេខច្រើនសូមប្រើ "ដកឃ្លា" ឬ "បញ្ចូល")',
        tradeLenLimitTips: 'យ៉ាងច្រើនអាចឆែកព័ត៌មាននៃវិក័យបត្របាន១០ខ្សែក្នុងពេលតែមួយ',
        safetyCertificate: 'អាជ្ញាប័ណ្ណការការពារគ្រោះអគ្គីភ័យ',
        searchRes: 'លទ្ធផលស្វែងរក：',
        trailTip1: 'ចំណាំ：',
        trailTip2: '绿色',
        trailTip3: 'គន្លងនៃពុម្ពអក្សរត្រូវបានផ្តល់ដោយក្រុមហ៊ុនផ្តល់សេវាភាគីទីបី។ វេទិកានេះគឺសម្រាប់តែការបង្ហាញប៉ុណ្ណោះ និងមិនធានានូវភាពត្រឹមត្រូវរបស់វានោះទេ។。',
        NoLenlimitTip: 'យ៉ាងច្រើនអាចបញ្ចូលលេខវិក្កយបត្ររហូតដល់10ខ្សែ!',
        NoRepeatTip: 'លេខ​វិក្កយបត្រ​ផ្ទួនគ្នា!',
        tradeTip: 'សូមបញ្ចូលលេខវិក្កយបត្រត្រឹមត្រូវ!',
        verificationTip: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់!',
        tradeNo: 'លេខ​វិក្កយបត្រ​',
        tradeNoErr: 'រកមិនឃើញលេខតាមដាន វាអាចនឹងមិនត្រូវបានបញ្ចូល ឬលេខតាមដានខុស',
        usedTime:'ពេលបានប្រើ',
        shipType:'វិធីដឹកជញ្ជូន',
    },
    en: {
        tradeNoQuery: 'Shipment Code',
        inSearchTips: 'Please enter the order number  (Please use "Space" or "Enter" for multiple order numbers)',
        tradeLenLimitTips: 'Up to 10 codes',
        safetyCertificate: 'Security Authentication',
        searchRes: 'Status:',
        trailTip1: 'Notes:',
        trailTip2: 'Green',
        trailTip3: 'Trajectories of font are provided by the third-party carriers. This platform only displays those trajectories and does not guarantee their accuracy.',
        NoLenlimitTip: 'Up to 10 tracking numbers!',
        NoRepeatTip: 'Repeated tracking number!',
        tradeTip: 'Please enter correct tracking number!',
        verificationTip: 'Please enter the verification code!',
        tradeNo: 'Tracking Number',
        tradeNoErr: 'The tracking number is not found, it may be unrecorded or wrong',
        usedTime:'Used time',
        shipType:'type of shipping',
    },
    hk: {
        tradeNoQuery: '運單查詢',
        inSearchTips: '請輸入要跟蹤的訂單單號（多個單號請使用“空格”或者“enter回車”）',
        tradeLenLimitTips: '最多支持同時查詢10條運單信息',
        safetyCertificate: '安全認證',
        searchRes: '查詢結果：',
        trailTip1: '注意：',
        trailTip2: '綠色',
        trailTip3: '字體的軌跡郵第三方承運商提供，本平台僅作展示，不擔保其準確性。',
        NoLenlimitTip: '最多輸入10个運單號！',
        NoRepeatTip: '運單號重複！',
        tradeTip: '請輸入有效運單號！',
        verificationTip: '請輸入驗證碼！',
        tradeNo: '運單號',
        tradeNoErr: '未搜索到該單號，可能尚未錄入或單號錯誤未搜索到該單號',
        usedTime:'已用时',
        shipType:'运输类型',
    }
}
