<template>
    <div class="zh-trade-query-root">
        <div class="trade-query-wrap min-w center">
            <PageTitle :title="t[lang].tradeNoQuery" />
            <div class="search-limit-tips t-center">
                {{ t[lang].tradeLenLimitTips }}
            </div>
            <ZhTradeInput ref="tradeInputDom" :origin="'trail'" @queryTrail="queryTrail" :lang="lang" />
            <div class="code-img-form-item">
                <div>
                    <div class="code-img-input-wrap">
                        <el-input minlength="4" maxlength="4" v-model.trim="verificationCode" autocomplete="off" width="50%" class="code-img-input"></el-input>
                    </div>
                    <div class="code-img-wrap">
                        <img :src="`https://bk.diditrack.com/track/verificationCode?${random}`" @click="random = Math.random()" />
                    </div>
                </div>
            </div>
            <div class="safe-title">
                {{ t[lang].safetyCertificate }}
            </div>
        </div>
        <div class="result-header min-w center" v-if="trailData.length">
            <div class="result-header-desc">{{ t[lang].searchRes }}</div>
            <div class="result-header-tips mt-20">
                <span>{{ t[lang].trailTip1 }}</span>
                <span class="green">{{ t[lang].trailTip2 }}</span>
                <span>{{ t[lang].trailTip3 }}</span>
            </div>
        </div>
        <Trail v-for="item in trailData" :key="item.tradeNo" :trailObjItem="item" :lang="lang" />
    </div>
</template>

<script>
import Trail from './Trail'
import ZhTradeInput from './TradeInput'
import PageTitle from './PageTitle'
import t from './config'
import { querySeaTrack } from './services/app'

// 域名映射轨迹来源字段
// locale映射语言
// const localeMapLang = {
//     zh: 1,
//     en: 2,
//     local: 3
// }

// lang字段 中文zh 英文en 柬埔寨语km
// 运输类型：shipType
// 耗时：usedTime
// 柬埔寨：intl.cambodia
// 香港：zto.xianggang.find

export default {
    name: 'HOME',
    data() {
        return {
            loading: false,
            verificationCode: '',
            random: undefined, // 验证码随机数
            trailData: [],
            lang: 'zh',
            lggType: 1, // 1是中文 2是英文 3是本地语
            t,
        }
    },
    layout: 'tradePage',
    components: { ZhTradeInput, Trail, PageTitle },
    mounted() {
        // this.getStorageTrailData()
    },
    methods: {
        // 查询轨迹
        queryTrail() {
            if (this.loading) return
            this.getQueryTrailNo()
        },
        // 获取运单号
        getQueryTrailNo() {
            let tradeNo = this.$refs.tradeInputDom.tradeNo
            let tradeNoArr = this.$refs.tradeInputDom.tradeNoArr

            this.$refs.tradeInputDom.tradeNo = ''
            if ((tradeNo && tradeNoArr.length + 1 > 10) || (!tradeNo && tradeNoArr.length > 10)) {
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].NoLenlimitTip,
                })
                return
            }
            // 运单号重复
            if (tradeNoArr.includes(tradeNo)) {
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].NoRepeatTip,
                })
                return
            }
            tradeNo && tradeNoArr.push(tradeNo)
            if (this.validForm(tradeNoArr)) {
                this.reqQueryTrail(tradeNoArr)
            }
        },
        // 请求接口查询轨迹
        async reqQueryTrail(tradeNoArr) {
            let params = {
                clientSource: 'SZDYCUSTOM',
                lggType: 1,
                data: tradeNoArr,
                verificationCode: this.verificationCode,
            }

            this.loading = true
            let res = await querySeaTrack(params)
            this.loading = false
            this.random = Math.random()
            this.verificationCode = ''
            if (res.success) {
                this.handleTrailData(tradeNoArr, res.data.data)
            }

            // this.handleTrailData(tradeNoArr, [{
            //     "mail": {
            //         "mailNo": "11",
            //         "isSign": 0
            //     },
            //     "shipType": null,
            //     "usedTime": null,
            //     "tracks": [{
            //         "mailNo": "11",
            //         "message": "派件",
            //         "messageEng": "Physical delivery scheduled.",
            //         "time": "2022-11-07 16:14:14",
            //         "zone": "东8区",
            //         "city": "",
            //         "place": "",
            //         "status": "4",
            //         "tpTrack": 0
            //     }, {
            //         "mailNo": "11",
            //         "message": "到件",
            //         "messageEng": "Arrival at inward office of exchange.",
            //         "time": "2022-08-31 09:46:53",
            //         "zone": "东8区",
            //         "city": "",
            //         "place": "长沙市",
            //         "status": "3",
            //         "tpTrack": 0
            //     }, {
            //         "mailNo": "11",
            //         "message": "派件",
            //         "messageEng": "Physical delivery scheduled.",
            //         "time": "2022-08-31 09:46:53",
            //         "zone": "东8区",
            //         "city": "",
            //         "place": "",
            //         "status": "4",
            //         "tpTrack": 0
            //     }]
            // }]
            // )
        },
        // 处理接口轨迹数据
        handleTrailData(tradeNoArr, data) {
            let trailData
            if (data.length) {
                trailData = data.map((v) => ({
                    tradeNo: v.mail.mailNo,
                    trail: v.tracks,
                    shipType: v.shipType,
                    usedTime: v.usedTime,
                }))
            } else {
                trailData = [{ tradeNo: '', trail: [], shipType: '', usedTime: '' }]
            }
            this.trailData = trailData

            // this.trailData = tradeNoArr.map(item1 => {
            //     for (let index = 0; index < data.length; index++) {
            //         if (data[index] && data[index].mail) {
            //             return {
            //                 tradeNo: data[index].mail.mailNo,
            //                 trail: data[index].tracks
            //             }
            //         }
            //     }
            //     return {
            //         tradeNo: item1,
            //         trail: []
            //     }
            // })
        },
        // 校验表单
        validForm(tradeNoArr) {
            if (!tradeNoArr.length) {
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].tradeTip,
                })
                return false
            }
            if (!this.verificationCode) {
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].verificationTip,
                })
                return false
            }
            return true
        },
        // 轨迹查询页面需获取缓存的轨迹记录
        getStorageTrailData() {
            this.trailData = sessionStorage.getItem('ztoglobal_trailData') ? JSON.parse(sessionStorage.getItem('ztoglobal_trailData')) : []
            if (sessionStorage.getItem('ztoglobal_trailData')) {
                sessionStorage.removeItem('ztoglobal_trailData')
            }
        },
    },
}
</script>

<style lang="scss">
.zh-trade-query-root {
    overflow: auto;
    background: #fff;
    padding: 24px;
    box-sizing: border-box;
    min-height: 100vh;
    .trade-query-wrap {
        background: linear-gradient(180deg, #fcfdff 0%, #f8fbff 100%);
        border-radius: 10px;
        padding: 30px 55px;
        box-sizing: border-box;
        margin-bottom: 20px;

        .search-limit-tips {
            margin-top: -10px;
            font-size: 16px;
            color: #999;
        }

        .btn_search {
            display: block;
            width: 270px;
            height: 58px;
            line-height: 58px;
            background: linear-gradient(180deg, #01b1f3 0%, #004da0 100%);
            border: none;
            border-radius: 40px;
            font-size: 16px;
            color: #fff;
            font-weight: bolder;

            img {
                vertical-align: middle;
                width: 16px;
                margin-right: 10px;
            }
        }

        .code-img-form-item {
            margin: 20px 0 0 0;
            display: flex;
            justify-content: center;

            .code-img-input-wrap {
                display: inline-block;
                width: 160px !important;

                .el-input__inner {
                    border: 1px solid #dce2e7;
                    background: #f7f9fa;
                }
            }

            .code-img-wrap {
                display: inline-block;
                vertical-align: top;
                width: 120px;
                height: 40px;
                margin-left: 20px;
                background: rgba(254, 254, 254, 1);
                border: 1px solid rgba(220, 226, 231, 1);
                text-align: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .result-header {
        .result-header-desc {
            font-size: 20px;
            font-weight: bolder;
        }

        .result-header-tips {
            margin: 10px 0 20px;
            font-size: 14px;
            color: #999;
        }
    }

    .safe-title {
        font-size: 20px;
        font-weight: bolder;
        text-align: center;
        padding-top: 18px;
    }
}
</style>
