import axios from 'axios'
import Vue from 'vue'
import { Message } from 'element-ui'

const CancelToken = axios.CancelToken

const httpPending = [] // 用于存储每个ajax请求的取消函数和ajax标识

// 取消请求方法
const cancelHttp = (name, config = {}) => {
    httpPending.forEach((e, i) => {
        if (e.n === name || e.n === config.xhrName) {
            // 当前请求在数组中存在时执行函数体
            e.f() // 执行取消操作
            httpPending.splice(i, 1) // 把这条记录从数组中移除
        }
    })
}

Vue.prototype.$cancelHttp = cancelHttp

// 修改默认配置
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true // 允许携带cookie

// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 取消上一次未完成的相同请求，注意项目中是否存在风险
        cancelHttp(null, config)

        config.cancelToken = new CancelToken((c) => {
            if (config.xhrName) {
                httpPending.push({
                    n: config.xhrName,
                    u: `${config.url}&${config.method}`,
                    f: c,
                })
            }
        })

        return config
    },
    (error) => Promise.reject(error)
)

// 响应拦截器
axios.interceptors.response.use(
    (res) => {
        cancelHttp(null, res.config) // 响应成功把已经完成的请求从 httpPending 中移除

        const response = res.data ? res.data : {}

        if (!response.success) {
            // 客户端才可弹框
            let message
            if (response.error.code === '99999') {
                message = Object.values(response.error.validationError.fieldErrors)[0]
            } else {
                message = response.error.message
            }

            // 请求异常
            Message({
                message: message || '服务器异常，请稍后再试',
                type: 'error',
            })
        }

        return Promise.resolve(response)
    },
    (error) => {
        // 请求异常
        Message({
            message: '服务器异常，请稍后再试',
            type: 'error',
        })

        Promise.reject(error)
    }
)

// HTTP工具类
export default class Http {
    static async request(method, url, opts) {
        // 开启本地mock的话，不使用接口域名
        let hostName = 'https://bk.diditrack.com'
        let uri = `${hostName}${url}`

        let params = {
            xhrName: (opts && opts.name) || '',
            method,
            url: uri,
        }

        if (method === 'GET') {
            params.params = opts.body || {}
        } else {
            params.data = opts.body || {}
        }

        params.headers = {
            lang: 'zh-CN',
        }

        return axios(params)
    }

    static get(url, opts) {
        return this.request('GET', url, opts)
    }

    static put(url, opts) {
        return this.request('PUT', url, opts)
    }

    static post(url, opts, headers) {
        return this.request('POST', url, opts, headers)
    }

    static patch(url, opts) {
        return this.request('PATCH', url, opts)
    }

    static delete(url, opts) {
        return this.request('DELETE', url, opts)
    }
}
