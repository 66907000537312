<template>
    <div class="zh-trail-wrap min-w center">
        <el-card shadow="never">
            <div slot="header" v-if="trailObjItem.tradeNo">{{ t[lang].tradeNo }}：{{ trailObjItem.tradeNo }}</div>
            <div class="trail-info-wrap" v-if="trailArr.length">
                <!-- <el-timeline>
                    <el-timeline-item v-for="(item, index) in trailArr" :key="index" :icon="item.icon" :type="item.type" :color="item.color" :size="item.size" :timestamp="item.time" placement="top">
                        <span :class="{ green: !item.tpTrack }">{{ item.message }}</span>
                    </el-timeline-item>
                </el-timeline> -->
                <el-timeline>
                    <el-timeline-item v-for="(item, index) in trailArr" :key="index" :icon="item.icon" :type="item.type" :timestamp="null" :color="item.color" :size="item.size" placement="top">
                        <div :class="{ green: !item.tpTrack }">
                            <span v-if="item.place" style="margin-right: 12px">
                                {{ item.place }}
                            </span>
                            {{ item.message }}
                        </div>
                        <div :class="{ green: !item.tpTrack }">{{ item.messageEng }}</div>
                        <div :class="{ zoneText: item.zone }">{{ item.zone ? '【' + item.zone + '】' : '' }}{{ item.time }}</div>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="no-trail" v-else>
                <img src="./assets/empty.png" />
                <div>{{ t[lang].tradeNoErr }}</div>
            </div>
        </el-card>
    </div>
</template>

<script>
import t from './config'
export default {
    name: 'ZhTrail',
    props: ['trailObjItem', 'trailDataLen', 'lang'],
    data() {
        return {
            trailArr: [],
            t,
        }
    },
    mounted() {
        this.trailArr = [...this.trailObjItem.trail]
    },
    methods: {},
}
</script>
<style lang="scss">
.zh-trail-wrap {
    margin-top: 20px;

    .trail-info-wrap {
        height: 480px;
        overflow-y: scroll;
    }

    .no-trail {
        padding: 30px 0 52px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bolder;
        text-align: center;

        img {
            width: 112px;
        }
    }

    .collape-operate-wrap {
        font-size: 18px;
        text-align: center;
        color: #0086ff;
    }

    .el-card {
        border: none;
    }

    // 面板头部
    .el-card__header {
        background: #f1f8ff;
        border-radius: 2px 2px 0px 0px;
        border: none;
        padding: 15px 32px;
        font-size: 14px;
        color: #333;
        font-weight: bold;
    }

    .el-card__body {
        background: #f9fcff;
        border-radius: 2px;
        padding: 20px 40px;
        box-sizing: border-box;
        font-weight: bolder;
    }

    // 重置时间轴样式
    .el-timeline-item__node {
        width: 18px;
        height: 18px;
        background: url('./assets/trail_dotto.png') no-repeat;
        background-size: contain;
    }

    .el-timeline-item:nth-child(1) .el-timeline-item__node {
        width: 18px;
        height: 18px;
        background: url('./assets/trail_dotto_active.png') no-repeat;
        background-size: contain;
    }

    .el-timeline-item:nth-child(1) {
        .el-timeline-item__timestamp.is-top,
        .el-timeline-item__content {
            color: #444;
        }
    }

    .el-timeline-item__wrapper {
        padding-left: 50px;
        top: 3px;

        .el-timeline-item__timestamp.is-top {
            font-size: 14px;
            color: #999;
            display: none; //新加的
        }

        .el-timeline-item__content {
            font-size: 14px;
            color: #999;
            line-height: 25px;
            position: relative; //新加的
            top: -5px;
        }
    }

    .el-timeline-item__node--normal {
        left: 5px;
    }

    .el-timeline-item__tail {
        border-left-width: 1px;
        left: 14px;
    }

    .el-timeline-item:nth-child(1) .el-timeline-item__tail {
        border-left: 1px dotted #003b8e;
    }

    .zoneText {
        //新加
        position: relative;
        left: -9px;
    }
    .d-flex {
        display: flex;
    }
}
</style>
