<template>
    <div class="zh-trade-input-wrap">
        <div class="trade-list-wrap" v-show="tradeNoArr.length">
            <ul>
                <li v-for="(item, index) in tradeNoArr" :key="index">
                    <span>{{ item }}</span>
                    <i class="el-icon-error hand" @click="deleteTrade(index)"></i>
                </li>
            </ul>
        </div>
        <div class="trade-textarea-wrap" ref="scrollIntoViewDom">
            <img class="search-input-icon" v-show="!tradeNoArr.length" src="./assets/search.png" />
            <!-- 分号keyCode为186,空格键为32 -->
            <el-input type="text" v-model="tradeNo" @focus="changeOpacity(true)" @blur="changeOpacity(false)" @input="tradeNo = validTrailNo(tradeNo)" @keydown.native.enter.186.32="appendTradeNo" maxlength="150" :class="{ mulLen: !tradeNoArr.length }" :placeholder="t[lang].inSearchTips"></el-input>
            <!-- placeholder="请输入要跟踪的订单单号（多个单号请使用“空格”或者“enter回车”）" -->
            <a @click="queryTrail" class="btn_search hand t-center center" :class="{ btn_search_pd: tradeNoArr.length }"><img src="./assets/btn_search.png" />{{ t[lang].tradeNoQuery }}</a>
        </div>
    </div>
</template>

<script>
import t from './config'
export default {
    name: 'TradeInput',
    props: ['origin', 'isFocus', 'lang'],
    data() {
        return {
            tradeNo: '',
            tradeNoArr: [],
            t,
        }
    },
    mounted() {
        this.getStorageTradeNo()
    },
    methods: {
        // 轨迹查询单号验证规则 只能输入数字字母和-和_
        validTrailNo(val) {
            const value = val.toString()
            return value.replace(/[^0-9a-zA-Z-_]/g, '')
        },
        // 聚焦失焦改变透明度
        changeOpacity(status) {
            if (this.origin !== 'trail') {
                this.$emit('triggleFocus', status)
            }
        },
        // 添加运单号
        appendTradeNo(e) {
            // 运单号为空
            if (!this.tradeNo) return
            // 运单列表超出长度限制
            if (this.tradeNoArr.length >= 10) {
                // 兼容IE10（含）以下
                this.$nextTick(() => {
                    this.tradeNo = ''
                })
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].NoLenlimitTip,
                })
                return
            }
            // 运单号重复
            if (this.tradeNoArr.includes(this.tradeNo)) {
                this.$nextTick(() => {
                    this.tradeNo = ''
                })
                this.$message({
                    type: 'warning',
                    message: this.t[this.lang].NoRepeatTip,
                })
                return
            }
            const selectionIndex = e.srcElement.selectionStart
            console.log('光标位置', selectionIndex)
            const str = selectionIndex > 0 ? this.tradeNo.slice(0, selectionIndex) : this.tradeNo
            this.tradeNoArr.push(str)
            // this.tradeNoArr.push(this.tradeNo)
            this.$nextTick(() => {
                // this.tradeNo = ''
                this.tradeNo = selectionIndex > 0 ? this.tradeNo.slice(selectionIndex) : ''
            })
        },
        // 删除运单号
        deleteTrade(index) {
            this.tradeNoArr.splice(index, 1)
        },
        queryTrail() {
            this.$emit('queryTrail')
        },
        // 轨迹查询页面需获取缓存的运单号
        getStorageTradeNo() {
            this.tradeNoArr = sessionStorage.getItem('ztoglobal_tradeNo') ? JSON.parse(sessionStorage.getItem('ztoglobal_tradeNo')) : []
            if (sessionStorage.getItem('ztoglobal_tradeNo')) {
                sessionStorage.removeItem('ztoglobal_tradeNo')
            }
        },
    },
}
</script>
<style lang="scss">
.zh-trade-input-wrap {
    margin-top: 40px;
    width: 100%;
    // 99
    // max-height: 110px;
    // overflow-y: auto;
    background: #f1f8ff;
    border-radius: 6px;
    .search-input-icon {
        vertical-align: middle;
        width: 24px;
        margin: 0px 0 0 24px;
    }
    .trade-list-wrap {
        background: #f1f8ff;
        padding: 20px 30px 0;
        box-sizing: border-box;
        ul {
            li {
                display: inline-block;
                padding: 10px 20px;
                margin: 0 5px 10px 0;
                box-sizing: border-box;
                background: #e5f1ff;
                border-radius: 18px;
                span {
                    display: inline-block;
                    border-right: 2px solid #fff;
                    padding-right: 15px;
                    box-sizing: border-box;
                    color: #333;
                    font-size: 16px;
                    margin-right: 10px;
                }
            }
        }
    }
    .trade-textarea-wrap {
        display: flex;
        align-items: center;
        .el-input {
            width: 1015px;
            height: 55px;
            font-size: 24px;
            &.mulLen {
                height: 83px;
            }
        }
        .el-input__inner {
            background: #f1f8ff;
            // background: red;
            padding-left: 30px;
            box-sizing: border-box;
            overflow: hidden; // 隐藏IE11以下滚动条样式
            resize: none;
            border: none;
            height: 100%;
            &::-webkit-input-placeholder {
                font-size: 14px;
                color: rgba(106, 106, 106, 0.7);
            }
        }
        .el-input__inner {
            resize: none;
            border: none;
        }
        .el-input ::-webkit-input-placeholder {
            /* WebKit browsers */
            font-size: 20px;
        }
        .el-input ::-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            font-size: 20px;
        }
        .el-input ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            font-size: 20px;
        }
        .el-input ::-ms-input-placeholder {
            /* Internet Explorer 10+ */
            font-size: 20px;
        }
        .btn_search_pd {
            margin-bottom: 12px;
            margin-right: 12px;
        }
    }
}
</style>
