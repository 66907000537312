<template>
    <div class="page-title">
        <div class="content">
            <div class="main-title" :style="{ color: titleColor, marginTop: anchorTop }">{{ title }}</div>
            <div class="line" :style="{ marginBottom: lineBottom }">
                <img src="./assets/xiaobiao@2x.png" />
            </div>
            <div class="minor-title" :style="{ color: contentColor, marginBottom: contentBottom, fontSize: contentFontSize || 'inherit' }" v-if="content">
                {{ content }}
            </div>
            <div v-if="showComma" class="page-title--comma"></div>
        </div>
        <div class="btn" v-if="isMore" @click="toMoreFun">{{ $t('cncommon.knowMore') }}</div>
    </div>
</template>

<script>
// title
export default {
    name: 'PageTitle',
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        titleColor: {
            type: String,
            default: '#333333',
        },
        content: {
            type: String,
            default: '',
        },
        contentColor: {
            type: String,
            default: '#666666',
        },
        contentFontSize: {
            type: String,
        },
        contentBottom: {
            type: String,
            default: '60px',
        },
        lineBottom: {
            type: String,
            default: '30px',
        },
        anchorTop: {
            type: String,
            default: '0px',
        },
        isMore: {
            type: Boolean,
            default: false,
        },
        showComma: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toMoreFun() {
            this.$emit('toMore')
        },
    },
}
</script>

<style lang="scss" scoped>
.page-title {
    width: 100%;
    margin: 0 auto;
    position: relative;
    .content {
        position: relative;
        width: 984px;
        margin: 0 auto;
        text-align: center;
        .page-title--comma {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url('./assets/comma.png');
            background-size: 100% auto;
            background-position: top;
        }
        .main-title {
            font-size: 36px;
            font-family: SourceHanSansSC-Bold, SourceHanSansSC;
            font-weight: bold;
            line-height: 36px;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
        .line {
            img {
                width: 88px;
                height: 10px;
            }
        }
        .minor-title {
            font-size: 16px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 60px;
        }
    }
    .btn {
        width: 192px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        background: linear-gradient(180deg, #edf1f7 0%, #ffffff 100%);
        box-shadow: 5px 5px 14px 0px rgba(206, 221, 233, 0.5);
        border-radius: 30px;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 2 2;

        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;

        position: absolute;
        top: 6px;
        right: 0;
        cursor: pointer;
    }
}
</style>
